<template>
  <div class="block">
    <template  v-if="ignoreBlock">
    </template>
    <template v-else>
      <component ref="comp" :is="componentId" v-if="!isCMS && componentId && pageEndSetting != 1"
        :data="tmplData" :tmplId="tmplId" @compAction="onCompAction"></component>
      <CmsBlock v-if="isCMS" :code="settings.name" :name="name" :data="settings"></CmsBlock>
    </template>
  </div>
</template>

<script>
import CmsBlock from './CmsBlock';

export default {
  name: 'dynamic-block',
  components: {
    CmsBlock,
  },
  props: {
    name: {
      type: String,
    },
    blockInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    console.log('AAAAAAAAA',this.name, this.blockInfo, this.data);
    const settings = this.$store.getters.storeGetBlockSettings(this.blockInfo);
    console.log('settings-------22',settings)
    const isCMS = settings && settings.module === 'cms';
    let tmplId = this.name;
    console.log('tmplId----', tmplId)
    let data = this.data;
    let ignoreBlock;
    let pageEndSetting = this.$store.state.pages.pageEndSetting;
    console.log('isCMS, settings------>',isCMS, settings);

    // 非 CMS 模块，加载 dynamic/components/ 目录下的 vue 文件
    if (!isCMS) {
        const theme = localStorage.getItem("theme")
        console.log('this.blockInfo------',this.blockInfo)
      if (this.blockInfo.name === 'bottom' || this.blockInfo.label === '底部导航' || this.blockInfo.name == '底部导航' || this.blockInfo.label == 'bottom') {
          switch(theme){
              case "junmin":
                tmplId = 'junminFooter';
                break;
              case "yingji":
                tmplId = 'yingjiFooter';
                break;
              case "anquanshengchan":
                tmplId = 'anquanFooter';
                break;
              case "shandongjiaotong":
                tmplId = 'sdjtFooter';
                break;
              case "shanghaijiaoda":
                tmplId = 'shjdFooter';
                break;
              case 'sdjzmh2':
                tmplId = 'sdjz2Footer';
                break;
            case 'zhaohumh':
                tmplId = 'zhaohuFooter';
                break;
          }
        data = settings;
     } else if (this.blockInfo.label === '侧悬浮组件' || this.blockInfo.name === 'side') {
        tmplId = 'Side';
        data = settings;

      } else if (this.blockInfo.label === '顶部导航' || this.blockInfo.name === 'head') {
        ignoreBlock = true;
      } else if (this.blockInfo.label === '标题栏' || this.blockInfo.name === 'titleBar') {
        tmplId = 'titleBar';
        data = settings;
      }
    }
    // if (!isCMS) {
    //   if (this.blockInfo.label === 'bottom') {
    //     tmplId = 'Footer';
    //     data = settings;
    //  } else if (this.blockInfo.label === 'side') {
    //     tmplId = 'Side';
    //     data = settings;

    //   } else if (this.blockInfo.label === 'head') {
    //     ignoreBlock = true;
    //   }
    // }
    // if not is adding component, use manually setted data
    // otherwise using props.data, hency you should watch this.props.data for updaing tmplData
    // otherwise will broken data reacting...
    // force update will update this
    // const data = this.data.IS_COMP_ADDING ? this.data : (this.tmplData || null);
    return {
      ignoreBlock,
      isCMS,
      settings,
      tmplData: data,
      componentId: null,
      tmplId,
      pageEndSetting
    };
  },
  computed: {
    loader() {
      return () => import(`../../components/${this.tmplId}`);
    }
  },
  watch: {
    'name': {
      handler() {
        this.render();
      },
    },
    data(val) {
      this.tmplData = val;
      // console.log(val);
    }
  },
  methods: {
    render() {
      // test this module getter can get specifiied module
      this.loader()
        .then(() => {
          // if this.loader can be called,
          // assign this module getter to component
          this.componentId = () => this.loader();
          console.log('this.componentId----',this.componentId)
        })
        .catch(() => {
          // pre setted loader  () => import(`../components/${this.data.name}`); not working
          // failback to module not found block
          if (this.$route.query.is_debug === '1973') {
            this.componentId = () => import('../../components/NotFound.vue');
          } else {
            this.componentId = '';
          }
        });
    },
    setData(data) {
        this.tmplData = data;
        // const fn = this.$refs.comp.setData;
        // fn && fn(data);
    },
    onCompAction(data) {
        this.$emit('compAction', data);
    }
  },
  created() {
    this.render();
  },
};
</script>

<style lang="less">
// .block {
//   padding: 20px;
//   // border: 1px solid red;
//   background: #fff;
// }
</style>
