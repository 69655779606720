<template>
  <div class="swiper-five" :style="swiperContainStyl">
    <div class="swiper-box" :style="swiperStyl">
      <el-carousel
        trigger="click"
        :height="receiveProps.tempData.specialStyle.oneGroupImageLeft?
        '426px': '444px'"
        :interval="receiveProps.tempData.specialData.swiperSpeed"
      >
        <div v-for="(item, index) in receiveProps.tempData.specialLeftData" :key="index">
          <el-carousel-item >
              <img
              :src="item.entryImg"
              alt=""
              class="img-main"
             @mouseover="overSwiperItem(item)"
             v-if="item.entryImg"
             @click="clickSwiperItem(item)"
          />
          <img src="../asset/images/default-img.jpg"
          v-if="!item.entryImg"
          class="img-main"
          @click="clickSwiperItem(item)"
          @mouseover="overSwiperItem(item)"
           alt="">
          <div class="video-overly" v-if="item.fileType == 2">
            <img src="../asset/images/video-btn.png"
            @click="clickSwiperItem(item)" alt=""
            @mouseover="overSwiperItem(item)">
            
          </div>
          <div class="title-bar" v-if="receiveProps.tempData.specialData.gridShowTitle"><span>{{item.titleName}}</span></div>
        </el-carousel-item>
        
        </div>
      </el-carousel>
       
    </div>
    <div class="gird-four-Box">
      <ul>
        <li
          class="select-img"
          v-for="(item, index) in receiveProps.tempData.specialRightData"
          :key="index"
          :style="selectImgStyl"
          @click="girdClickItem(item)"
        >
              <img :src="item.entryImg" 
              @mouseover="overSwiperItem(item)"
              v-if="item.entryImg"
               alt="" />
               <img src="../asset/images/default-img.jpg"
                v-if="!item.entryImg"
                alt=""  @mouseover="overSwiperItem(item)">
               <div class="video-overly-grid" v-if="item.fileType == 2"  @mouseover="overSwiperItem(item)">
            <img src="../asset/images/video-btn.png" alt="">
          </div>
          <div class="title-bar" v-if="receiveProps.tempData.specialData.gridShowTitle">{{item.titleName}}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {commonClickFn} from './componentArticle/commonModuleFn'
export default {
  name: "carouselGrid",
  props: {
    receiveProps: {
      type: Object,
      default: () => {
        return {
          tempData:{
            specialStyle:{
              oneGroupImageLeft: false
            },
            specialData:{
              swiperSpeed: 3000
            }
          }
        }
      },
    },
  },
  computed: {
    swiperContainStyl(){
      let {oneGroupImageLeft} = this.receiveProps.tempData.specialStyle
      return {
        "--contain-padding": oneGroupImageLeft? '24px': '0'
      }
    },
    swiperStyl() {
      let {oneGroupImageLeft} = this.receiveProps.tempData.specialStyle
      return {
        "--swiper-box-width": oneGroupImageLeft? "568px" : "592px", 
        "--swiper-box-height": oneGroupImageLeft? "426px": '444px',
        "--swiper-box-hover": this.showPointer?'pointer': ''
      };
    },
    selectImgStyl(){
      let {oneGroupImageLeft} = this.receiveProps.tempData.specialStyle
      return {
        "--select-img-width": oneGroupImageLeft? '268px': '280px',
        "--select-img-height": oneGroupImageLeft? '201px': '210px',
        "--select-hover": this.showPointer? 'pointer': ''
      }
    }
  },
  data() {
    return {
      showPointer: false
    };
  },
  methods: {
    clickSwiperItem(item) {
      // 点击轮播图
      commonClickFn(item,'',this, true )
    },
    girdClickItem(item) {
      commonClickFn(item,'', this, true);
    },
    overSwiperItem(item){
      let {selectType, videoUrl} = item
      console.log('item参数内容----》', item)
      if(selectType || videoUrl){
        this.showPointer = true
        return
      }
      this.showPointer = false
      
    }
  },
};
</script>
<style lang="less" scoped>
.swiper-box:hover{
    cursor: var(--swiper-box-hover);
  }
.swiper-five {
  width: calc(1200px - 48px);
  justify-content: center;
  margin: 24px auto;
  display: flex;
  padding: var(--contain-padding);
  .img-main {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .video-overly{
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -48px 0 0 -48px;
    width: 96px;
    height: 96px;
    img{
      width: 96px;
      height: 96px;
    }
  }
  
  .img-main:hover, ::v-deep .img-main:hover{
    cursor: var(--select-hover);
  }
  .swiper-box {
    position: relative;
    width: var(--swiper-box-width);
    height: var(--swiper-box-height);
    .title-bar{
          z-index: 100;
          width: calc(100% - 2px);
          height: 32px;
          background-color: #000;
          opacity: 0.7;
          color: #fff;
          padding: 6px 8px;
          box-sizing: border-box;
          position: absolute;
          left: 1px;
          bottom: 0;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
    }
  }
  
  .gird-four-Box {
    margin-left: 24px;
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr ;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
      .select-img {
        position: relative;
        z-index: 11;
        width: var(--select-img-width);
        height: var(--select-img-height);
        img {
          width: 100%;
          height: 100%;
        }
        .title-bar{
          width: calc(100% - 2px);
          height: 32px;
          background-color: #000;
          opacity: 0.7;
          color: #fff;
          padding: 6px 8px;
          box-sizing: border-box;
          position: absolute;
          left: 1px;
          bottom: 0;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }
        .video-overly-grid{
          position: absolute;
          left: 50%;
          top: 50%;
          margin: -24px 0 0 -24px;
          width: 48px;
          height: 48px;
          img{
            width: 48px;
            height: 48px;
          }
        }
      }
      .select-img:hover{
        cursor: var(--select-hover);
      }
    }
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
  /deep/ .el-carousel__button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  /deep/ .el-carousel__indicators--horizontal {
    z-index: 10000;
    left: 84%;
  }
}
</style>