<script>
import Vue from "vue";
const blocks = require.context("./", true, /vueBlock.js$/);
let idMap = {};
let newMap = {};
blocks.keys().forEach(async (key) => {
  let blockPrarentName = key.split("/")[1];
  let itemBlock = {};
  idMap = {
    ...blocks(key).default,
  };
  (function (name, data) {
    for (var [k, v] of Object.entries(data)) {
      itemBlock[`${name}-${k}`] = v;
      console.log(`${name}-${k}`, "===", v.__file);
      newMap = { ...newMap, ...itemBlock };
    }
  })(blockPrarentName, idMap);
});
console.log("idMap---->", idMap);

// todo may be has a prop "config" or "data"
export default {
  props: {
    name: {
      type: [String, Number],
      required: true,
    },
    blockInfo:{
      type:Object,
      default(){}
    },
    receiveProps: {
      type: Object,
      default() {
        return {
          terminal: "0", // 0 h5端 1 pc端数据
          tempData: {
            // 区块基本交互属性
            specialData: {
              specialName: "轮播图", // 区块名字
              id: "146", // 区块id
              swiperSpeed: 3000/*轮播图切换速度 "3000 4000 5000"*/,
              leftSwiperSpeed: 3000 /*一分区内容左边轮播图切换时间*/,
              bannerVideoUrl: "ww" /*视频banner  视频地址*/,
              bannerCoverImage: "", // 视频banner 封面图
              templateType:
                "1" /* 图文组合的模版类型 "1" "2"  按照ui图从上到下拼接*/,
              showRows: "1" /* 图文组合显示行数 "1" 单行 "2" 多行*/,
              showTotal: "100" /* 图文组合共显示多少个 最多"100" */,
              includeItemsImageOrVideo: true /*"0"不包含 "1" 包含  是否包含图片/视频*/,
              includeItemsTitle: true /*  "0"不包含 "1" 包含  是否标题  */,
              includeItemsDes: true /*  "0"不包含 "1" 包含  是否包含描述  */,
              showContentType:
                "1" /*  展示内容来源 "1" 自由设置 "2".直接调取  */,
              commendsRule: "1" /*  推荐规则 "1" 人工 "2" 最新 "3" 最热  */,
              showPublishFlag: false /*  是否显示发表时间 */,
              showReadFlag: false /*  是否显示查看人数 */,
              swiperWrap: "1" /*轮播宽度 "1 正常宽度1200 2" 横向平铺*/,
              bannerWrap:
                "1" /*视频banner宽度 "1" 正常宽度1200 "2" 横向平铺*/,
              articleClassId: "" /*图文组合文章分类id"*/,
              classTitle: "" /*图文组合文章分类标题"*/,
              description: "" /*图文组合文章分类描述"*/,
              gridShowTitle: "", // 分区模块是否显示标题
            },

            specialStyle: {
              titleSize: "12" /*标题文字大小*/,
              titleColor: "#DCDFE6" /*标题颜色*/,
              titleBold: false /*标题是否加粗*/,
              titleAlign: "left" /*标题对齐方式*/,
              descAlign: "center" /*描述对齐方式*/,
              descBold: true /*描述加粗*/,
              descColor: "#DCDFE6" /*描述颜色*/,
              descSize: "12" /*描述字号*/,
              linkBtnAlign: "right" /*按钮链接对齐 left center right*/,
              imgRadius: true /*图片圆满圆角*/,
              imgShadow: true /*图片阴影*/,
              imgBorder: true /*图片边框*/,
              imgBorderColor: "#DCDFE6" /*图片边框颜色*/,
              linkBtnLocation: "top" /* 按钮链接位置 top bottom*/,
              imgBackgroundColor: "#DCDFE6" /*图片背景颜色*/,
              blockMargeTop: "10" /*区块整体 上边距*/,
              blockMargeBottom: "10" /*区块整体 下边距*/,
              blockBGClolor: "#ccc" /*区块整体背景颜色*/,
              oneGroupBorder: false /*  单个组合单元 是否有边框 */,
              oneGroupBGColor: "#DCDFE6" /*   单个组合单元 背景颜色 */,
              oneGroupBorderRadius: false /*   单个组合单元是否圆角 */,
              oneGroupBorderShadow: false /*   单个组合单元是否阴影 */,
              oneGroupImageLeft: false /*   单个组合单元图片是否贴边 */,
            },

            // 按钮区块数据
            buttonContentData: {
              buttonWidth: "100" /*按钮模块 按钮宽度*/,
              buttonHeight: "100" /*按钮模块 按钮高度*/,
              buttonColor: "#DCDFE6" /*按钮模块 按钮颜色*/,
              buttonFontSize: "12" /*按钮模块  按钮字号*/,
              buttonFontWeight: true /*按钮模块  按钮文字加粗*/,
              buttonDes: "按钮区块文字" /*按钮模块 按钮文字*/,
              buttonUrlType:
                "3" /*1" 文章 "2".自定义页面 "3".系统已有页面 "4".链接* "5"文章分类*/,
              " buttonUrl": "https://www.baidu.com" /*按钮模块 跳转链接*/,
              buttonArticleId: "2" /*按钮模块 文章id*/,
              buttonOpenModel:
                "1" /*按钮模块 打开方式 "1"当前标签打开 "2" 新标签打开*/,
              urlType: "1" /* 按钮模块 "1".站内链接 "2".站外链接*/,
              articleClassIds:
                "['2105171','2105171','2105171']" /*  多个文章分类id */,
            },

            // 标题区块数据
            titleContentData: {
              titleName: "标题栏标题",
              titleDes: "标题栏描述",
              titleUrl: "标题栏跳转链接",
              titleUrlName: "标题栏跳转链接文字",
              articleClassIds: "['2105171','2105171','2105171']", // 文章分类id
              articleId: "2", // 文章id
              openModel: "1" /* 打开方式 "1"当前标签打开 "2" 新标签打开*/,
              urlType: "1" /* 跳转模块 "1".站内链接 "2".站外链接*/,
              titleUrlType:
                "1" /*跳转链接类型 "1" 文章 "2".自定义页面 "3".系统已有页面 "4".链接* "5"文章分类*/,
            },

            // 文章区块数据
            articleContentData: {
              articleId: "210517102756155814", // 文章id
              releaseTimeStatus: false /*是否显示文章发布时间*/,
              readingStatus: false /*是否显示阅读状态*/,
              articleTitleStatus: false /*是否显示文章标题*/,
              articleName: "文章名称" /*文章标题*/,
            },

            // 查看更多按钮
            buttonData: {
              isShowButton: false, //是否显示查看更多按钮
              articleClassIds:
                "['2105171','2105171','2105171']" /*  多个文章分类id */,
              buttonName: "按钮" /*  按钮名称 */,
              entryType:
                "3" /*按钮模块 按钮跳转链接类型 "1" 文章 "2".自定义页面 "3".系统已有页面 "4".链接*/,
              entryUrl: "https://www.baidu.com" /*按钮模块 跳转链接*/,
              articleId: "2" /* 文章id*/,
              openModel:
                "1" /*按钮模块 打开方式 "1"当前标签打开 "2" 新标签打开*/,
              urlType: "1" /*  "1".站内链接 "2".站外链接 */,
            },
            // 除了左一右四区块以外的所有区块条目数组
            specialEntryList: [
              {
                fileId: "1", // 选择的图片 视频 文章 文件id
                fileType: "1", // 选择的条目的文件类型 文章 视频 图片
                titleName: "条目主标题",
                titleDes: "条目副标题",
                buttonName: "按钮" /*  按钮名称 */,
                entryType:
                  "3" /*按钮模块 按钮跳转链接类型 "1" 文章 "2".自定义页面 "3".系统已有页面 "4".链接 "5"文章分类*/,
                entryUrl: "https://www.baidu.com" /*按钮模块 跳转链接*/,
                openModel:
                  "1" /*按钮模块 打开方式 "1"当前标签打开 "2" 新标签打开*/,
                urlType: "1" /*  "1".站内链接 "2".站外链接 */,
                articleId: "210517102756155814" /* 文章id*/,
                imageId: "", // 图片id
                videoId: "", //视频id
                articleClassIds:
                  "['2105171','2105171','2105171']" /*  多个文章分类id */,
              },
            ],

            // 左一右四区块左边数据
            specialLeftData: [
              {
                fileId: "1", // 选择的图片 视频 文章 文件id

                fileType: "1", // 选择的条目的文件类型 文章 视频 图片

                titleName: "条目主标题",
                titleDes: "条目副标题",

                title: "按钮" /*  按钮名称 */,
                entryType:
                  "3" /*按钮模块 按钮跳转链接类型 "1" 文章 "2".自定义页面 "3".系统已有页面 "4".链接*/,
                entryUrl: "https://www.baidu.com" /*按钮模块 跳转链接*/,
                openModel:
                  "1" /*按钮模块 打开方式 "1"当前标签打开 "2" 新标签打开*/,
                urlType: "1" /*  "1".站内链接 "2".站外链接 */,
                articleId: "210517102756155814" /* 文章id*/,
                imageId: "", // 图片id
                videoId: "", //视频id
                articleClassIds:
                  "['2105171','2105171','2105171']" /*  多个文章分类id */,
              },
            ],

            // 左一右四区块右边数据
            specialRightData: [
              {
                fileId: "1", // 选择的图片 视频 文章 文件id
                fileType: "1", // 选择的条目的文件类型 文章 视频 图片
                titleName: "条目主标题",
                titleDes: "条目副标题",
                title: "按钮" /*  按钮名称 */,
                entryType:
                  "3" /*按钮模块 按钮跳转链接类型 "1" 文章 "2".自定义页面 "3".系统已有页面 "4".链接*/,
                entryUrl: "https://www.baidu.com" /*按钮模块 跳转链接*/,
                openModel:
                  "1" /*按钮模块 打开方式 "1"当前标签打开 "2" 新标签打开*/,
                urlType: "1" /*  "1".站内链接 "2".站外链接 */,
                articleId: "210517102756155814" /* 文章id*/,
                imageId: "", // 图片id
                videoId: "", //视频id
                articleClassIds:
                  "['2105171','2105171','2105171']" /*  多个文章分类id */,
              },
            ],
          },
        };
      },
    },
    routerParams: {
      type: Object,
      default() {
        return {};
      },
    },
    setting: {
      type: [Object, Array],
      default() {
        return {};
      },
    },
  },
  mounted() {},
  render(h) {
    const block = newMap[this.name]; // name等于 modules目录下 block父级文件夹名称加"-"加block的name
    block.class = this.name;
    console.log('block---------->999', block)
    if (block) {
      return h(block, {
        on: this.$listeners,
        attrs: this.$attrs,
        scopedSlots: this.$sensorsopedSlots,
        props: this.$props,
      });
    }
    if (Vue.config.productionTip) {
      return h("div", [`unrecongnized block "${this.name}"`]);
    }
    return null;
  },
};
</script>
