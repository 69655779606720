<template>
    <div class="cms-yingji-home">        
      <comp-render :data="pageConf.components" 
      v-if="!isLoading" :render-only="true"></comp-render>
      <!-- <temp-render
        :ref="'temp-' + tempCode"
        :code="tempCode"
        :attr="tempObj"
        v-if="isRender && pageEndSetting == 1"
        @action="handleOpenLocalPath"
      /> -->
      <tempFooter :tempData="tempObj" v-if="isRender && pageEndSetting == 1"/>
    </div>
</template>

<script>
import CompRender from './dynamicComp/CompRender';
import tempFooter from '../components/tempFooter.vue'
import { mapGetters, mapMutations } from 'vuex';

export default {
    components: {
      CompRender,
      tempFooter
    },
    provide() {
      return {
        isDebug: this.$route.query.is_debug === '1973',
      }
    },
    mounted() {
    },
    data() {
        const pageName = this.$route.name;
        console.log(this.$route);
        const pageConf = this.$store.getters.storeGetPageConfByName(pageName);
        this.storeSetPageSettings(pageConf.blockContent.blockList);

        return {
          isLoading: false,
          code: 'A',
          pageConf: pageConf,
          tempCode: 'Footer',
          pageEndSetting: null,
          isRender: false,
          tempObj: {}
        }
    },
    computed: {
      ...mapGetters(['storeGetPageConfByName']),
    },
    created() {
      this.initTemp();
    },
    methods: {
        ...mapMutations(['storeSetPageSettings','storePageEndSetting']),
      async initTemp () {
        let tempData = JSON.parse(localStorage.getItem('setTempConfigs'));
        if (tempData.pageEnd == null) return;
        tempData.pageEnd.token = localStorage.getItem('token');
        this.pageEndSetting = tempData.pageEndSetting;
        this.storePageEndSetting(tempData.pageEndSetting);
        this.tempObj.pageEnd = tempData.pageEnd;
        this.isRender = true;
      },
      handleOpenLocalPath(data) {
        console.log(data.data.localPath,data.data.openMode)
        if (data.data.openMode == 1) {
          this.$router.push({
            path: data.data.localPath
          })
        } else {
           const { href } = this.$router.resolve({
              path: data.data.localPath
          });
          window.open(href, '_brank');
        }
      }
    }
}
</script>

<style lang="less">
.cms-yingji-home {
  background: #fff;
  // height: 100%;
  // min-height: 1000px;
}
.junminptop {
  background: #fff;
  height: 100%;
}
.comp-empty {
    outline: 1px dashed #555 !important;
    outline-offset: -1px !important;
    box-shadow: inset 0 0 0 4px rgba(0, 0, 0, 10%), inset 0 0 0 4px rgba(255, 255, 255, 20%), inset 0 0 0 1px rgba(255, 255, 255, 30%);
    padding-bottom: 75px;
    padding-right: 75px;
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
    background: #fff;
}  
</style>