<template>
  <div v-if="allArticleData" class="articleDetailLayout">
    <div class="articleTop">
      <div v-if="allArticleData.articleCover" class="aritcleTopLeft fl">
        <img
          :src="allArticleData.articleCover"
          :alt="allArticleData.articleTitle"
          v-if="allArticleData.articleCover"
        />
      </div>
      <div class="aritcleTopRight fl">
        <p class="aritcleTopRightTitle ">
          {{ allArticleData.articleTitle }}
        </p>
        <div class="aritcleTopRightTitleDetail">
          <span v-if="(showPublishFlag==0||showPublishFlag=='')">发表时间：{{allArticleData.publishTime|formatTime}}</span>

          <!--          <span v-if="(showPublishFlag==0||showPublishFlag=='')&&allArticleData.sortType==2">发表时间：{{allArticleData.customTime|formatTime}}</span>-->
          <span v-if="showReadFlag==0||showReadFlag==''" class="seeNum">
            {{ allArticleData.readNum || 0 }}
          </span>
        </div>
      </div>
    </div>
    <div class="articleCont" v-html="allArticleData.content"></div>
    <div
      class="article-video"
      v-if="allArticleData.articleVideoUrl"
    >
      <video
        controls
        autoplay
        :src="allArticleData.articleVideoUrl"
        class="vdeo-play"
      >
      </video>
    </div>
   <div v-if="allArticleData.enclosureName"  class="articleBottom">
      <div class="articleBottomLeft">
        <div class="fujianItem">
          <div  class="fujianItemLeft">
            <p class="fujianTitle fl">附件：</p>
            <p class="yasuobaoTitle fl">
              {{ allArticleData.enclosureName }}
<!--              <span>{{ allArticleData.enclosureSize }}MB</span>-->
            </p>
          </div>
          <div class="fujianItemRight fr">
            <a
              :href="allArticleData.enclosureUrl"
              class="fujianDownload"
              target="_blank"
              >预览</a
            >
          </div>
        </div>

        <div v-if="allArticleData.externalName" class="fujianItem friendLinkItem">
          <div class="fujianItemLeft">
            <p class="fujianTitle fl">相关链接：</p>
            <p class="yasuobaoTitle renliziyuankiaoshiLink fl">
              {{ allArticleData.externalName }}
            </p>
          </div>
          <div class="fujianItemRight fr">
            <a
              :href="allArticleData.externalUrl"
              class="lijiqianwang"
              target="_blank"
              >立即前往</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "articleDetial",
  data() {
    return {
      articleId: "",
      allArticleData: null,
      showReadFlag:this.$route.query.showReadFlag?this.$route.query.showReadFlag:'',
      showPublishFlag:this.$route.query.showPublishFlag?this.$route.query.showPublishFlag:''
    };
  },
  filters: {
    formatTime(val) {
      return (
        val.split(" ")[0].split("-").join("/") +
        " " +
        val.split(" ")[1].split(":").slice(0, 2).join(":")
      );
    },
  },
  created() {
    this.getArctileDetail();
  },
  computed: {},
  mounted() {},

  beforeCreate () {
    // document.querySelector('body').setAttribute('style', 'background-color:red;')

    document.querySelector('body').setAttribute('style', 'background-color:#ffffff;')
  },

  beforeDestroy () {
    document.querySelector('body').removeAttribute('style')
  },

  methods: {
    getArctileDetail() {

      console.log('测试++++文章数据');

      console.log(this.$route.query.articleId);

      // this.$api.news
      //   .getArticleDetail({ id: this.$route.query.articleId })
      //   .then(async (res) => {
      //     console.log('resresresresresresresres',res)
      //     if (res.success) {
      //       this.allArticleData = res.data;
      //     } else {
      //       this.$message.error(res.message);
      //     }
      //   });

      this.$api.news
        .getArticleDetailNew({
          articleId: this.$route.query.articleId,
          readTag:1
        })
        .then(async (res) => {
          console.log('resresresresresresresres',res)
          if (res.success) {
            this.allArticleData = res.data.list[0];
          } else {
            this.$message.error(res.message);
          }
        });
    },
  },
};
</script>

<style scoped>
@import "../asset/articleDetail.css";

</style>
