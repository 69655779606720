<template>
  <div class="picture-article" :style="picAndArt" @click="clickLink" >
    <div class="img-box" v-if="specialEntryList.entryImg" >
      <div class="overlay" :style="overlayStyl" @click="clickLink" v-if="specialEntryList.entryImg&&(specialEntryList.titleName || specialEntryList.titleDes)"></div>
      <img id="imgId" :src="specialEntryList.entryImg" :style="imageStyl"/>
    </div>
    <div class="title-box" :style="titleBoxStyl">
        <div class="title" :style="mainTitle" >
      {{
        specialEntryList.titleName
      }}
    </div>
    <div class="inner-text" :style="halfTitle">
      {{specialEntryList.titleDes}}
    </div>
    </div>
  </div>
</template>

<script>
import {commonClickFn} from './componentArticle/commonModuleFn'
export default {
  name: "graphicOverlay",
  props: {
    receiveProps: {
      type: Object,
      default: () => {},
    },
  },
  data(){
    return {
      specialEntryList: this.receiveProps.tempData.specialEntryList[0]
    }
  },
  computed: {
    picAndArt() {
      let {blockMargeTop, blockMargeBottom, blockBGClolor} = this.receiveProps.tempData.specialStyle
      // let containHeight = this.$refs.imgRefs.clientHeight
      return {
        "--all-margin-top": `${blockMargeTop}px`,
        "--all-margin-bottom": `${blockMargeBottom}px`,
        "--all-background-color": blockBGClolor,
        // height: `${this.$refs.imgRefs.clientHeight}px`
      };
    },
    imageStyl() {
        let {imgBackgroundColor, imgBorder, imgShadow, imgRadius} = this.receiveProps.tempData.specialStyle
      return {
        border: imgBorder? '1px solid #D6DEEA': '',
        boxShadow: imgShadow?'0px 0px 16px 0px rgba(0, 0, 0, 0.1)':'',
        borderRadius: imgRadius? '8px': '',
        backgroundColor: imgBackgroundColor,
      };
    },

    overlayStyl(){
      // imgBackgroundColor
              let { imgBorder, imgShadow, imgRadius} = this.receiveProps.tempData.specialStyle
      return {
        border: imgBorder? '1px solid #D6DEEA': '',
        boxShadow: imgShadow?'0px 0px 16px 0px rgba(0, 0, 0, 0.1)':'',
        borderRadius: imgRadius? '8px': '',
        backgroundColor: '#000',
      }
    },
    titleBoxStyl(){
      let {imageId} = this.receiveProps.tempData.specialEntryList[0]
        return {
            "--title-box-position": imageId?'absolute': '' // 有图的时候--> absolute  没有---> ''
        }
    },
    mainTitle() {
        let {titleAlign, titleBold, titleColor, titleSize} = this.receiveProps.tempData.specialStyle
      return {
        "--main-font-color": titleColor,
        "--main-font-size": `${titleSize || 24}px`,
        "--main-font-weight": titleBold? '700': '',
        "--main-text-align": titleAlign
      };
    },
    halfTitle() {
      let {descSize, descColor, descBold, descAlign} = this.receiveProps.tempData.specialStyle
      return {
        "--half-font-color": descColor,
        "--half-font-size": `${descSize || 14}px`,
        "--half-font-weight": descBold? "700":"",
        "--half-text-align": descAlign,
      };
    },
  },
  methods: {
    clickLink(){
      commonClickFn(this.receiveProps.tempData.specialEntryList[0], this.receiveProps.tempData.specialData, this, true)
    }
  },
};
</script>

<style lang="less" scoped>
.picture-article {
  z-index: 2;
  width: 1200px;
  // height: auto;
  margin: 0 auto;
  position: relative;
  margin-top: var(--all-margin-top);
  margin-bottom: var(--all-margin-bottom);
  background-color: var(--all-background-color);
  display: flex;
  align-items: center;
  .img-box {
    font-size: 0;
    z-index: -1;
    width: 1200px;
    position: relative;
    border: var(--image-border);
      border-radius: var(--image-border-radius);
      background-color: var(--image-background-color);
    .overlay{
      position: absolute;
      // background: #000000;
      opacity: 0.6;
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      
      
    }
  }
  .title-box{
      width: 100%;
      position: var(--title-box-position);
  }
  .title {
    margin: 24px;
    color: var(--main-font-color);
    font-size: var(--main-font-size);
    font-weight: var(--main-font-weight);
    text-align: var(--main-text-align);
  }
  .inner-text {
    color: var(--half-font-color);
    font-size: var(--half-font-size);
    font-weight: var(--half-font-weight);
    text-align: var(--half-text-align);
    word-break: break-all;
    margin: 0 24px 24px;
  }
}
</style>
