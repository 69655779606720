<template>
  <div
    class="button-container"
    :style="buttonContainerStyl"
    ref="buttonContainer"
  >
    <div class="btn-box" :style="wdButton" ref="wdButton" @click="clickLink">
      <span :style="btnText" ref="btnText">{{receiveProps.tempData.buttonContentData.buttonDes}}</span>
    </div>
  </div>
</template>

<script>
import {commonClickFn} from './componentArticle/commonModuleFn'
export default {
  name: "optionButton",
  props: {
      receiveProps:{
          type: Object,
          default: ()=>{}
      }
  },
  computed: {
    buttonContainerStyl() {
        let {blockMargeTop, blockMargeBottom, blockBGClolor, titleAlign} = this.receiveProps.tempData.specialStyle
      return {
        "--all-margin-top": `${blockMargeTop}px`,
        "--all-margin-bottom": `${blockMargeBottom}px`,
        "--all-background-color": blockBGClolor,
        "--all-flex": titleAlign=='left'?'flex-start':titleAlign == 'center'? 'center': 'flex-end'
      };
    },
    wdButton() {
        let {buttonWidth, buttonHeight, buttonColor} = this.receiveProps.tempData.buttonContentData
      return {
        "--button-width": `${buttonWidth}px`,
        "--button-height": `${buttonHeight}px`,
        "--button-background-color": buttonColor || '#1972ff',
      };
    },
    btnText() {
        let {titleSize, titleColor, titleBold} = this.receiveProps.tempData.specialStyle
      return {
        "--text-font-color": titleColor || '#fff',
        "--text-font-size": `${titleSize || 16}px`,
        "--text-font-weight": titleBold? '700': ''
      };
    },
  },
  methods: {
    clickLink(){ 
       commonClickFn(this.receiveProps.tempData.buttonContentData,'', this, true)
    }
  },
};
</script>

<style lang="less" scoped>
.button-container {
  width: 1200px;
  margin: 0 auto;
  margin-top: var(--all-margin-top);
  margin-bottom: var(--all-margin-bottom);
  background-color: var(--all-background-color);
  padding: 16px 0;
  display: flex;
  justify-content: var(--all-flex);
  .btn-box:hover {
    cursor: pointer;
  }
  .btn-box {
    display: flex;
    align-items: center;
    width: var(--button-width);
    height: var(--button-height);
    border-radius: 4px;
    margin: 0 24px;
    background-color: var(--button-background-color);
    span {
      display: inline-block;
      width: 100%;
      color: var(--text-font-color);
      font-size: var(--text-font-size);
      font-weight: var(--text-font-weight);
      text-align: center;
      font-family: PingFangSC-Regular, PingFang SC;
    }
  }
}
</style>