<template>
  <div>
    <div class="page-container common-page" v-loading.fullscreen.lock="isLoading">
      <create-page :data="config.components"  v-if="!isLoading"></create-page>
    </div>
    <div v-if="pageEndSetting == 1">
      <!-- <temp-render
        :ref="'temp-' + tempCode"
        :code="tempCode"
        :attr="tempObj"
        v-if="isRender"
        @action="handleOpenLocalPath"
      /> -->
      <tempFooter :tempData="tempObj" v-if="isRender" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import CreatePage from "../../utils/commonPageVnodeCreate.js";
import tempFooter from '../../modules/dynamic/components/tempFooter.vue'
export default {
  name: "commonpage",
  components: {
    CreatePage,
    tempFooter
  },
  data() {
    const pageNames = this.$route.path;
    // const pageList = JSON.parse(localStorage.getItem('setPageConfigs'))
    // const pageConf = pageList.find(page => ((page.uri&&page.uri.includes(pageNames)) || (page.manual_url&&page.manual_url.includes(pageNames))));
    const pageConf = this.$store.getters.getConfigByPage(pageNames);
    this.loadSettings(pageConf);
    return {
      isLoading: true,
      config: {},
      routerParams: {},
      tempCode: 'Footer',
      pageEndSetting: null,
      isRender: false,
      tempObj: {}
    };
  },
   provide() {
        return {
          isDebug: this.$route.query.is_debug === '1973',
        }
    },
  computed: {
    ...mapGetters(["storeGetPageConfByName"]),
  },
  watch: {
    $route() {
      const { params } = this.$route.query;
      const { path } = this.$route;
      // const pageList = JSON.parse(localStorage.getItem('setPageConfigs'))
      // this.config =  pageList.find(page => ((page.uri&&page.uri.includes(path)) || (page.manual_url&&page.manual_url.includes(path))));
      this.config = this.$store.getters.getConfigByPage(path);
      this.routerParams = params;
    },
  },
  created() {
    const { params } = this.$route.query;
    const { path } = this.$route;
    // const pageList = JSON.parse(localStorage.getItem('setPageConfigs'))
    // this.config = pageList.find(page => ((page.uri&&page.uri.includes(path)) || (page.manual_url&&page.manual_url.includes(path))));
     this.config = this.$store.getters.getConfigByPage(path);
    this.routerParams = params;
  },
  mounted() {
    this.initTemp()
  },
  methods: {
    ...mapMutations(["storeSetPageSettings"]),
    loadSettings(pageConf) {
      setTimeout(()=>{
             this.storeSetPageSettings(pageConf.blockContent.blockList);
            this.isLoading = false
      },300)

      //   });
    },
    initTemp () {
      let tempData = JSON.parse(localStorage.getItem('setTempConfigs'));
      if (tempData.pageEnd == null) return;
      tempData.pageEnd.token = localStorage.getItem('token') || '';
      this.pageEndSetting = tempData.pageEndSetting;
      this.tempObj.pageEnd = tempData.pageEnd;
      this.isRender = true;
    },
    // handleOpenLocalPath(data) {
    //   if (data.data.openMode == 1) {
    //     this.$router.push({
    //       path: data.data.localPath
    //     })
    //   } else {
    //       const { href } = this.$router.resolve({
    //         path: data.data.localPath
    //     });
    //     window.open(href, '_brank');
    //   }
    // }
  },
};
</script>
<style lang="stylus" scoped>
.page-container {
  width:100%;
  margin: 0 auto;
  // margin-top: 24px;
}
.block{
  margin: 0 auto
}

/deep/ div[data-comp-name="12-12"]{ // 2分栏样式
  width 1200px;
  margin 0 auto
}
/deep/ div[data-comp-name="Tabs"]{ // tabs样式
  width 1200px;
  margin 0 auto
}
/deep/ .el-tabs__nav-scroll{
  background-color #fff;
  padding: 0 15px;
}

/**
// .page-container {
//   margin: 0 auto;
//   margin-top: 24px;
//   .isroot{
//     margin:0 auto;
//     > .el-col-24{
//       width:100%;
//       > .el-row{

//             width:1200px;
//             margin:0 auto;

//       }
//     }
//   }
// }
**/
</style>
