// 点击事件
export const commonClickFn = (item, specialData, _this, moduleName) => {
  let {
    openModel,
    selectType,
    selectUrlType,
    freedomPageUrl,
    systemPageUrl,
    linkUrl,
    id,
    articleId,
    fileType,
    urlType,
    videoUrl,
    url,
    classId
  } = item;

  let { showContentType, showReadFlag, showPublishFlag } = specialData;
  if((moduleName || showContentType == 1)&&fileType == 2){ // 视频情况
    _this.$router.push({
      path: '/cms/video',
      query: {
        entryUrl: videoUrl || url
      }
    })
    return
  }
  if (showContentType == 2) { // 直接调取情况
    if (item.enclosureType == 1) {
      window.open(item.enclosureUrl);
      return;
    }
      _this.$router.push({
        path: "/cms/detail",
        query: {
          articleId: id? id: articleId,
          showReadFlag: !moduleName?showReadFlag? 0 : 1 : '',
          showPublishFlag: !moduleName?showPublishFlag? 0 : 1 : ''
        },
      });
    return;
  }
  if (!selectType && !selectUrlType) return;
  let skipUrl = "";
  _this.$api.news.getArticleInfo({ articleId: articleId}).then(res=>{
    // 请求接口   判断文章情况下是否存在pdf   enclosureType==1直接打开
      if(res.data[0]&&res.data[0].enclosureType == 1){
          window.open(res.data[0].enclosureUrl)
      } else {
        // 自由设置情况下出现的跳转情况
        switch (selectType || selectUrlType) {
            case 1:
              skipUrl = _this.$router.resolve({
                path: "/cms/detail",
                query: {
                  articleId: articleId,
                  showReadFlag: !moduleName? showReadFlag? 0 : 1 : '',
                  showPublishFlag: !moduleName? showPublishFlag? 0 : 1 : ''
                },
              });
              break;
            case 2:
              skipUrl = freedomPageUrl;
              break;
            case 3:
              skipUrl = systemPageUrl;
              break;
            case 4:
              if (urlType == 1) {
                skipUrl = _this.$router.resolve({
                  path: linkUrl,
                  query: {
                    articleId: articleId,
                  },
                });
              } else {
                skipUrl = linkUrl;
              }
              break;
            case 5:
              skipUrl = _this.$router.resolve({
                path: "/cms/list",
                query: {
                  classId: classId,
                },
              });
              break;
          }
          let routerUrl = typeof skipUrl == "object" ? skipUrl.href : skipUrl;
          if (openModel == 1) {
            window.location.href = routerUrl; // 路由跳转需要结构出href
            return;
          }
          window.open(routerUrl);
      }
  })
};

// 图文   点击查看更多
export const checkMoreClickFn = (item, specialData, _this, moduleName)=>{
  let {
    openModel,
    selectType,
    selectUrlType,
    freedomPageUrl,
    systemPageUrl,
    linkUrl,
    articleId,
    urlType,
    classId
  } = item;

  let {  showReadFlag, showPublishFlag } = specialData;
  if (!selectType && !selectUrlType) return;
  let skipUrl = "";
  _this.$api.news.getArticleInfo({ articleId: articleId}).then(res=>{
    if(res.data[0]&&res.data[0].enclosureType == 1){ // 判断文章是否存在pdf
        window.open(res.data[0].enclosureUrl)
    } else {
      // 正常设置连接跳转情况下
      switch (selectType || selectUrlType) {
          case 1:
            skipUrl = _this.$router.resolve({
              path: "/cms/detail",
              query: {
                articleId: articleId,
                showReadFlag: !moduleName? showReadFlag? 0 : 1 : '',
                showPublishFlag: !moduleName? showPublishFlag? 0 : 1 : ''
              },
            });
            break;
          case 2:
            skipUrl = freedomPageUrl;
            break;
          case 3:
            skipUrl = systemPageUrl;
            break;
          case 4:
            if (urlType == 1) {
              skipUrl = _this.$router.resolve({
                path: linkUrl,
                query: {
                  articleId: articleId,
                },
              });
            } else {
              skipUrl = linkUrl;
            }
            break;
          case 5:
            skipUrl = _this.$router.resolve({
              path: "/cms/list",
              query: {
                classId: classId,
              },
            });
            break;
        }
        let routerUrl = typeof skipUrl == "object" ? skipUrl.href : skipUrl;
        if (openModel == 1) {
          window.location.href = routerUrl; // 路由跳转需要结构出href
          return;
        }
        window.open(routerUrl);
    }
   
})
}

// 图片等比缩放
export const changeImgSizeFn = (url, className, imgWidthSize) => {
  var img = new Image();
  img.src = url;
  let res = {};
  img.onload = function() {
    res = {
      width: img.width,
      height: img.height,
    };
    if (imgWidthSize > res.width) {
      // 大于宽度进行压缩  小于宽度原尺寸
      document.getElementsByClassName(className)[0].innerHTML = `<img src="${img.src}">`;
      return;
    }
    let realHeight = ((imgWidthSize) / res.width) * res.height;
    document.getElementsByClassName(className)[0].innerHTML = `<img src="${img.src}"  style="height: ${realHeight}px">`;
  };
};

// 获取数据
export const getData = (_this, tempNum)=>{ 
    // 规则获取文章列表
    console.log('')
    let {articleClassID, showContentType, showTotal, commendsRule} = _this.receiveProps.tempData.specialData
    let {oneGroupImageLeft, oneGroupBorder} = _this.receiveProps.tempData.specialStyle
    let widthObj = {
        1: !_this.isBanner?Number(oneGroupImageLeft)?564:532:Number(oneGroupImageLeft)?552:520, // 2个
        2:  Number(oneGroupImageLeft)? 368: 336, // 3个
        3:  !_this.isBanner?Number(oneGroupImageLeft)?270: 238:Number(oneGroupImageLeft)?264: 232, // 4个
        4:  Number(oneGroupImageLeft)?211:191, // 5个
        5:  !_this.isBanner?Number(oneGroupImageLeft)?172:152:Number(oneGroupImageLeft)?168:148 // 6个
    }
     _this.$api.news.getArticleInfo(
       { classId: articleClassID,
      sort: commendsRule}).then(res=>{
       _this.showContentType = showContentType
       if(showContentType == '2'){
         _this.articleDataList = showTotal?res.data.slice(0,showTotal):res.data
         _this.showImgUrl = 'articleCover'
         _this.showMainTile = 'articleTitle'
         _this.showMainDesc = 'articleAbstract' 
         _this.isFileType = 'articleType'
       } else {
         _this.articleDataList = showTotal?_this.receiveProps.tempData.specialEntryList.slice(0,showTotal):_this.receiveProps.tempData.specialEntryList
       _this.showImgUrl = 'entryImg'
       _this.showMainTile = 'titleName'
       _this.showMainDesc = 'titleDes'
       _this.isFileType = 'fileType'
       }
       _this.articleDataList.map((item, index)=>{
         console.log('宽度的显示是多少', widthObj[tempNum])
         if(item[_this.showImgUrl]){
          changeImgSizeFn(item[_this.showImgUrl], `img${_this.receiveProps.blockInstanceId}${index}`, oneGroupBorder?widthObj[tempNum]-2:widthObj[tempNum] )
         } else {
          changeImgSizeFn(require('../../asset/images/default-img.jpg'), `img${_this.receiveProps.blockInstanceId}${index}`, oneGroupBorder?widthObj[tempNum]-2:widthObj[tempNum] )
         }
       })
     })
  }
