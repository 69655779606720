<template>
  <div class="video-contaienr">
    <!-- <video
      class="video-box"
      :style="videoStyl"
      :src="receiveProps.tempData.specialData.bannerVideoUrl"
      autoplay
      muted
      loop
    ></video> -->
    <VideoBox
    class="video-box"
    :style="videoStyl"
    :videoUrl="receiveProps.tempData.specialData.bannerVideoUrl"/>
  </div>
</template>

<script>
export default {
  name: "videoBanner",
  props: {
    receiveProps: {
      type: Object,
      default: () => {},
    },
  },
  components:{
    VideoBox: ()=>import('./components/VideoBannerCom')
  },
  computed: {
    videoStyl() {
      let { bannerWrap } = this.receiveProps.tempData.specialData;
      return {
        "--video-width": bannerWrap == 1 ? "1200px" : "100%",
        "--video-height": bannerWrap == 1 ? "650px" : "800px",
      };
    },
  },
};
</script>

<style lang="less" scoped>
.video-contaienr {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  .video-box {
    object-fit: fill;
    width: var(--video-width);
    height: var(--video-height);
  }
}
</style>
