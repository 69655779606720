<template>
    <div class="swiper-containers" :style="imgSwiperStyl">
    <div class="swiper-wrap">
      <div class="swiper-containers">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in receiveProps.tempData.specialEntryList"
            :key="index"
          >
              <div class="inner-swiper" @mouseover="overSwiperItem(item)">
              <div class="image-swiper" @click="clickSwiperItem(item)" :class="`img${receiveProps.blockInstanceId}${index}`" :style="imgSwiperStyl">
              <img :src="item.thumbnail" alt="" class="img-main" v-if="specialData.swiperWrap==1"/>
              <!-- <img class="overly-btn" src="../asset/images/video-btn.png" alt=""> -->
              
              </div>
              <div class="title-bar" v-if="specialData.swiperTitleStatus"><span>{{item.swiperTitle}}</span></div>
              
            </div>
          </div>
        </div>
      </div>
      <!-- Add Arrows -->
      <div class="button-box _clear">
        <div class="swiper-button-next"><img src="../asset/images/right-btn.png" alt=""></div>
        <div class="swiper-button-prev"><img src="../asset/images/left-btn.png" alt=""></div>
      </div>
      <div class="swiper-pagination" slot="pagination"></div>
    </div>
  </div>
</template>


<script>
import Swiper from "swiper"
import "swiper/dist/css/swiper.min.css"
import {commonClickFn} from './componentArticle/commonModuleFn'
export default {
  name: "Carousel",
  props: {
    receiveProps: {
      type: Object,
      default: () => {
        return {
          tempData: {
            specialData: {
              swiperSpeed: 3000,
            },
            specialEntryList: [
              {
                thumbnail:
                  "1",
              },
            ],
          },
        };
      },
    },
  },
  mounted(){   
    console.log('------>specialData', this.specialData) 
    this.receiveProps.tempData.specialEntryList.map((item, index)=>{
       this.changeImgSizeFn(item.thumbnail, `img${this.receiveProps.blockInstanceId}${index}`, document.body.clientWidth)
    })
    setTimeout(() => {
      this.initSwiper()
    }, 1000);
  },
  data() {
    return {
      showPointer: false,
      specialData: this.receiveProps.tempData.specialData,
    };
  },
  computed:{
    imgSwiperStyl(){
      return {
        width: this.specialData.swiperWrap == 1? '1200px': '100%',
        height: this.specialData.swiperWrap == 1? '469px': '',
        '--img-hover': this.showPointer? 'pointer': ''
      }
    }
  },
  methods: {
    initSwiper() {
      if (this.swiper != null) return;
      this.swiper = new Swiper(".swiper-containers", {
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 1000,
        autoplay: {
          delay: this.receiveProps.tempData.specialData.swiperSpeed
        },
        // observer:true,//修改swiper自己或子元素时，自动初始化swiper 
        observeParents:true,
        effect : 'slide',
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
         pagination:{
          el:'.swiper-pagination',
          clickable:true,
        },
      });
    },
    clickSwiperItem(item) {
      commonClickFn(item, '', this, true )
    },
    overSwiperItem(item) {
      console.log('item相关参数', item)
      let {selectUrlType} = item
      if(selectUrlType){
        this.showPointer = true
        return
      }
      this.showPointer = false
    },
    changeImgSizeFn (url, className, imgWidthSize) {
      if(this.specialData.swiperWrap == 1) return 
    var img = new Image();
    img.src = url;
    let res = {};
    img.onload = function() {
      res = {
        width: img.width,
        height: img.height,
      };
      let realHeight = (imgWidthSize / res.width) * res.height;
      document.getElementsByClassName(className)[0].innerHTML = `<img src="${img.src}"  style="height: ${realHeight}px">`;
  };
}
  },
};
</script>
<style lang="less" scoped>

.swiper-containers{
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  .swiper-slide{
    width: 100%;
    position: relative;
    .title-bar{
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    padding-left: 8px;
    width: 100%;
    height: 32px;
    background: #000000;
    background: rgba(0,0,0,0.7);
    color: #fff;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 32px;
      }
    .image-swiper{
      position: relative;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .overly-btn{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 120px!important;
      height: 120px!important;
    }
  }
  .button-box{
    display: none;
    .swiper-button-prev{
      position: absolute;
    display: flex;
    // align-content: center;
    left: 0;
    background-image: none;
  }
  .swiper-button-next{
    position: absolute;
    display: flex;
    right: -5px;
    // align-content: center;
    background-image: none;
  }
  }
  .swiper-pagination{
    left: 0;
    bottom: 0;
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    /deep/.swiper-pagination-bullet{
      width: 8px;
      height: 8px;
      margin: 0 2px;
    }
  }
  &:hover{
    cursor: var(--img-hover);
    .button-box{
      display: block;
    }
  }
}

</style>
