<template>
  <div class="article-overall" ref="articleContainRefs">
    <component :is="componentTemp" :receiveProps="receiveProps"/>  
  </div>
</template>

<script>
export default {
  name: 'graphicCombination',
  props:{
    receiveProps:{
      type: Object,
      default: ()=>{}
    }
  },
  data(){
    return {
      moduleType: 'articleLR-thr',
      componentTemp: ()=>import('./componentArticle/articleLR-one'),
      isBanner: false // 通栏为true  分栏为false
    }
  },
  mounted(){
    this.changeModularFn()
  },
  methods:{
    changeModularFn(){
      let {templateType} = this.receiveProps.tempData.specialData  // 模板组合
      const activeUrl = {
        1: 'articleLR-one',
        2: 'articleLR-two',
        3: 'articleLR-thr',
        4: 'articleTB-two',
        5: 'articleTB-thr',
        6: 'articleTB-four',
        7: 'articleTB-five',
        8: 'articleTB-six'
      }
      this.componentTemp = ()=>import(`./componentArticle/${activeUrl[templateType]}`)
    }
  }

}
</script>

<style>

</style>