<template>
  <div class="article-content" :style="articleContent" ref="articleContent">
    <div class="title-box" v-if="isShowTitleBox">
      <p
        class="main-title"
        v-if="receiveProps.tempData.articleContentData.articleTitleStatus"
      >
        {{ articleData.articleTitle }}
      </p>
      <p class="publish-box">
        <span
        class="release-time"
        v-if="receiveProps.tempData.articleContentData.releaseTimeStatus"
        >
        {{ articleData.publishTime }}
        </span
      >
      <span
        class="obser-num"
        v-if="receiveProps.tempData.articleContentData.readingStatus"
        >
        <img src="../asset/images/obser-num-img.png" style="width: 14px;height: 14px">{{ articleData.readNum }}
        </span>
      </p>   
      
    </div>
    <div class="content-box" v-html="articleData.content" v-if="!isVideoArticle"></div>
    <div class="content-box-video" v-if="isVideoArticle">
      <VideoBox :videoUrl="articleData.articleVideoUrl" :isAuto= false />
    </div>
  </div>
</template>

<script>
export default {
  name: "articleContent",
  props: {
    receiveProps: {
      type: Object,
      default:()=>{}
    },
    data: {
      type: Object,
      default: ()=>{}
    },
  },
  components:{
    VideoBox: ()=>import('./components/VideoBannerCom')
  },
  computed: {
    articleContent() {
      let {
        blockMargeTop,
        blockMargeBottom,
        blockBGClolor,
      } = this.receiveProps.tempData.specialStyle;

      return {
        // 整体区块
        "--all-margin-top": `${blockMargeTop}px`,
        "--all-margin-bottom": `${blockMargeBottom}px`,
        "--all-background-color": blockBGClolor,
      };
    },
  },
  data() {
    return {
      articleData: {},
      isShowTitleBox: true,
      isVideoArticle: false, // 判断视频文章 || 图文文章
    };
  },
  mounted() {

    this.getArticleData();
  },
  methods: {
    getArticleData() {
      let {
        articleTitleStatus,
        releaseTimeStatus,
        readingStatus,
        articleId
      } = this.receiveProps.tempData.articleContentData;
      if (!articleTitleStatus && !releaseTimeStatus && !readingStatus) {
        this.isShowTitleBox = false;
      } else {
        this.isShowTitleBox = true;
      }

      this.$api.news
        .getArticleInfo({ articleId: articleId,readTag:1})
        .then((res) => {
          console.log('------->res', res)
          this.articleData = res.data[0];
          this.isVideoArticle = res.data[0].articleType == 2?true: false
          console.log('isvideo====>', this.articleData)
        });
    },
  },
};
</script>

<style lang="less" scoped>
.article-content {
  width: 1200px;
  margin: 0 auto;
  margin-top: var(--all-margin-top);
  margin-bottom: var(--all-margin-bottom);
  background-color: var(--all-background-color);
  .title-box {
    padding: 24px;
    border-bottom: 1px solid #ddd;
    .main-title {
      width: 1152px;
      height: 26px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 26px;
      margin-bottom: 8px;
    }
    .release-time {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      margin-right: 24px;
    }
    .obser-num {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      display: inline-flex;
      align-items: center;
      img{
            margin-right: 8px;
          }
    }
  }
  .content-box {

    margin-top: 1px;
    padding: 24px;
    width: 1152px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
    // text-indent: 2em; 缩进两字节
  }
  .content-box-video{
    margin-top: 1px;
    width: 1152px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
  }
  .publish-box{
    display: flex;
    align-items: center;
  }
}
</style>