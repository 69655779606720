
// import { idGenerator,findModulePosition } from './utils/index'
import DynamicBlock from '../modules/dynamic/page/dynamicComp/DynamicBlock.vue'
import CustomBlock from "../modules/index.vue";
export default {
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    renderOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      compList: [],
      parentId: 0,
      dragData: null,
      direction: 'bottom',//拖动位置
    };
  },
  components: {
    DynamicBlock,
    CustomBlock
  },
  methods: {
    // dragInset(dataArray, id, data, direction) {
    //   if (!dataArray || !data) return
    //   dataArray.map((element, i) => {
    //     if (element.id === id) {
    //       switch (direction) {
    //         case 'top':
    //           dataArray.splice(i - 1, 0, data)
    //           break;
    //         case 'bottom':
    //           dataArray.splice(i + 1, 0, data)
    //           break;
    //       }
    //       dataArray.splice(i-1, 1)
          
    //     } else {
    //       this.dragInset(element.children, id, data, direction)
    //     }
    //   });
    //   console.log('dataArray-----999999999', dataArray)
    // },
    //拖动的位置
    // setDrection(ev, type = "block") {
    //   let container = this.findParentByClass(ev.target, type)
    //   if (container) {
    //     const rect = container.getBoundingClientRect();
    //     this.direction = (ev.clientY - rect.top) < (rect.height / 2) ? 'top' : 'bottom';
    //   }
    // },
    //获取拖动的数据
    getdargData(dargId, dataList) {  
      dataList.map(element => {
        if (element.id == dargId) {
          this.dragData = element
        } else {
          if (element.children) {
            this.getdargData(dargId, element.children)
          }
          return
        }
      });
      return this.dragData
    },
    findParentByClass(elem, c) {
      let container = elem;
      while (container.tagName !== 'BODY'
        && !container.classList.contains(c)) {
        container = container.parentNode;
      }
      return container.tagName === 'BODY' ? null : container;
    },
    //获取
    getIdPostion(positionId, data, dataList) {
      dataList.map((element) => {
        if (element.id === positionId) {
          element.children.push(data)
          return element
        } else {
          if (element.children) {
            this.getIdPostion(positionId, data, element.children)
          }
        }
      });
    },
    getBlockPostion(id, data, datalist) {
      datalist.map((element) => {
        if (element.id === id) {
          datalist.push(data)
        } else if (element.children) {
          this.getBlockPostion(id, data, element.children)
        }
      });
    },
    renderConfig(h, data) {
      if (!data) { return console.error('no data'); }
      let childNodes = [];
      const option = {};
      option.class = data.class || {};
      option.style = data.style || {};
      option.attrs = data.attrs || {};
      option.props = data.props || {};
      option.domProps = data.domProps || {};
      option.on = data.on || {};
      option.nativeOn = data.nativeOn || {};
      option.ref = data.ref || data.id;
      option.key = data.key || data.id;
      if (data.tag === 'div') {
        delete option.nativeOn;      
      }
      if (data.id) {
        option.attrs['data-id'] = `${data.id}`;
        option.attrs['data-comp-name'] = `${data.name}` || `{data.type}`;
        option.class[`dynamic-comp-id-${data.id}`] = true;
        option.class['isroot'] = data.rootElem;
        option.class['data-comp-name'] = `${data.name}` || `{data.type}`;
      }
      if (data.tag === 'el-col') {
        option.props.name = data.name;
        option.attrs['data-id'] = `${data.id}`;
        option.attrs['span'] = data.span;
        option.class[`dynamic-comp-id-${data.id}`] = true;
      }
      if (data.children) {
        childNodes = data.children.map((n) => this.renderConfig(h, n));
      }

      if(data.tag === 'el-tabs') {
        option.attrs['stretch']= data.stretch;
      }
      if (data.type === 'block') {
          if(data.isDefined){
            option.props.name = data.name;
          } else {
            option.props.name = data.label;
          }
          option.props.blockInfo = { 
            id: data.id,
            label: data.label,
            name:data.name,
          };   
          console.log('settings-----data',data)
        if(data.isDefined){
          const settings = this.$store.getters.storeGetBlockSettings(option.props.blockInfo);
           if(settings){
            option.props.receiveProps = settings.setting[0]
          }
          console.log('option.props---->', option.props)
           return h('custom-block', option, childNodes);
        } else {
          return h('dynamic-block', option, childNodes);
        }
      } else {
        return h(data.tag, option, childNodes);
      }

    },
  },
  render(h) {
    this.compList = this.data;
    const childNodes = this.compList.map((n) => this.renderConfig(h, n));
    const result = h('div', childNodes);
    return result;
  }
};
